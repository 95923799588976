<template>
  <div>
    <b-card class="card-statistics">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <app-collapse>
          <app-collapse-item
            title="Filtros"
          >
            <div
              class="mb-1 col-md-8"
            >
              <b-row>
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <label for="example-datepicker">Data inicial</label>
                    <b-form-input
                      id="example-datepicker"
                      v-model="initialDate"
                      locale="pt"
                      type="date"
                      class="mb-1"
                    />
                  </div>

                  <div class="col-md-4">
                    <label for="example-datepicker">Data final</label>
                    <b-form-input
                      id="example-datepicker"
                      v-model="endDate"
                      locale="pt"
                      type="date"
                      class="mb-1"
                    />
                  </div>
                  <div class="mb-1 col-md-10">
                    <label>Credores</label>
                    <v-select
                      v-model="credoresSelected"
                      multiple
                      :options="credoresOptions"
                      :close-on-select="false"
                    />
                  </div>
                  <b-col
                    md="10"
                    class="mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-2 mb-1"
                      @click="validarFiltro"
                    >
                      Filtrar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-warning"
                      class="mb-1 mr-2"
                      @click="resetFilters"
                    >
                      Resetar filtro
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-success"
                      class="mb-1 mr-2"
                      @click="exportToExcel"
                    >
                      Exportar em Excel
                    </b-button>
                  </b-col>
                </div>
              </b-row>
            </div>

          </app-collapse-item>
        </app-collapse>
      </b-overlay>
    </b-card>
    <b-card v-if="showActions">
      <b-row>
        <b-col
          v-for="(item, i) in cards"
          :key="item.icon"
          xl="2"
          sm="3"
          class="cursor-pointer mb-1 ml-1"
          @click="itemClick(i)"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-1">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-col v-if="itemClicado === 0">
        <b-table
          responsive
          striped
          bordered
          :fields="fields"
          :items="itemsLigacoes"
          :per-page="perPage"
          :current-page="currentPage"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsLigacoes.length"
          :per-page="perPage"
          align="center"
          size="md"
          class="my-0"
        />
      </b-col>

      <b-col
        v-if="itemClicado === 1"
      >
        <b-table
          responsive
          striped
          :items="itemsSMS"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsSMS.length"
          :per-page="perPage"
          align="center"
          size="md"
          class="my-0"
        />
      </b-col>

      <b-col
        v-if="itemClicado === 2"
      >
        <b-table
          responsive
          striped
          :items="itemsEmails"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsEmails.length"
          :per-page="perPage"
          align="center"
          size="md"
          class="my-0"
        />

      </b-col>
      <b-col
        v-if="itemClicado === 3"
      >
        <b-table
          responsive
          striped
          :items="itemsWhatsApp"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsWhatsApp.length"
          :per-page="perPage"
          align="center"
          size="md"
          class="my-0"
        />
      </b-col>
    </b-card>
  </div>
</template>

<script>

// IMmportando dependecias de estilos
import {
  BRow,
  BCol,
  VBPopover,
  BCard,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
  BPagination,
  BMedia,
  BOverlay, BFormInput, BButton,

} from 'bootstrap-vue'

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import Ripple from 'vue-ripple-directive'

import { saveAs } from 'file-saver'
import ExcelJS from 'exceljs'

import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    vSelect,
    BButton,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    BMedia,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      itemsSMS: [],
      itemsEmails: [],
      itemsWhatsApp: [],
      itemsLigacoes: [],
      itemClicado: 0,
      tabelaClicada: null,
      itemSelected: [],
      items: [],
      fields: [
        { key: 'credor', label: 'Credor', class: 'text-center' },
        { key: 'cliente_nome', label: 'Nome do Cliente', class: 'text-center' },
        { key: 'cpf', label: 'CPF', class: 'text-center' },
      ],
      perPage: 15,
      currentPage: 1,
      cards: [],
      totaisLigacoes: 0,
      totaisSMS: 0,
      totaisEmails: 0,
      totaisWhatsApp: 0,
      load: false,
      initialDate: '',
      endDate: '',
      showActions: false,
      credoresOptions: [],
      credoresSelected: [],
    }
  },
  async created() {
    const date = new Date()
    this.initialDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().slice(0, 10)
    this.endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().slice(0, 10)
    await this.getCredores()
  },
  methods: {
    async getCredores() {
      const credor = JSON.parse(localStorage.getItem('credores'))
      credor.map(item => {
        this.credoresOptions.push({
          id: item.id,
          label: item.nome,
        })
      })
    },
    async itemClick(data) {
      if (data === 0) {
        this.itemClicado = 0
      }
      if (data === 1) {
        this.itemClicado = 1
      }
      if (data === 2) {
        this.itemClicado = 2
      }
      if (data === 3) {
        this.itemClicado = 3
      }
    },
    validarFiltro() {
      const errors = []

      if (this.credoresSelected.length === 0) {
        errors.push('Você esqueceu de selecionar suas unidades para realizar o filtro.')
      }

      if (this.initialDate === '' || this.endDate === '') {
        errors.push('Você esqueceu de selecionar o intervalo de data para realizar o filtro.')
      }

      if (errors.length > 0) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: errors.join('\n'),
        })
      } else {
        this.getActions()
      }
    },
    async getActions() {
      this.resetItems()
      this.load = true
      this.showActions = false
      const body = {
        data_inicial: `${this.initialDate} 00:00:00`,
        data_final: `${this.endDate} 23:59:59`,
        credores: this.credoresSelected.map(item => item.id),
      }
      await axios
        .post('api/v1/relatorios/backoffice/acoes/totalizadores', body)
        .then(res => {
          this.load = false
          this.showActions = true
          this.itemsLigacoes = res.data.dados.ligacoes
          this.totaisLigacoes = this.itemsLigacoes.length

          this.itemsSMS = res.data.dados.sms
          this.totaisSMS = this.itemsSMS.length

          this.itemsEmails = res.data.dados.emails
          this.totaisEmails = this.itemsEmails.length

          this.itemsWhatsApp = res.data.dados.whatsapp
          this.totaisWhatsApp = this.itemsWhatsApp.length
          this.setCards()
        })
        .catch(() => {
          this.load = false
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Parece que essa solicitação demorou demais, tente diminuir o intervalo entre as datas',
          })
        })
    },

    async setCards() {
      this.cards = [
        {
          color: 'light-info',
          customClass: '',
          icon: 'ActivityIcon',
          subtitle: 'LIGAÇÕES',
          title: this.totaisLigacoes,
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'AlertTriangleIcon',
          subtitle: 'SMS',
          title: this.totaisSMS,
        },
        {
          color: 'light-primary',
          customClass: '',
          icon: 'ClockIcon',
          subtitle: 'EMAILS',
          title: this.totaisEmails,
        },
        {
          color: 'light-info',
          customClass: '',
          icon: 'WhatsAppIcon',
          subtitle: 'WHATSAPP',
          title: this.totaisWhatsApp,
        },
      ]
    },

    resetItems() {
      this.itemsSMS = []
      this.itemsEmails = []
      this.itemsWhatsApp = []
      this.itemsLigacoes = []
    },
    resetFilters() {
      this.initialDate = ''
      this.endDate = ''
    },
    async exportToExcel() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()

      const workSheetTotais = workbook.addWorksheet('Resumo')
      const worksheetLigacoes = workbook.addWorksheet('Ligações')
      const worksheetSms = workbook.addWorksheet('SMS')
      const worksheetEmails = workbook.addWorksheet('E-mails')
      const worksheetWhatsapp = workbook.addWorksheet('Whatsapp')

      workSheetTotais.columns = [
        { key: 'ligacoes', header: 'Ligações' },
        { key: 'sms', header: 'Sms' },
        { key: 'emails', header: 'E-mails' },
        { key: 'whatsapp', header: 'Whatsapp' },
      ]
      worksheetLigacoes.columns = [
        { key: 'credor', header: 'CREDOR' },
        { key: 'cliente_nome', header: 'NOME DO CLIENTE' },
        { key: 'cpf', header: 'CPF' },
        { key: 'data', header: 'DATA' },
        { key: 'data_envio', header: 'HORÁRIO' },
        { key: 'resultado', header: 'RESULTADO' },
        { key: 'origem', header: 'ORIGEM' },
      ]
      worksheetSms.columns = [
        { key: 'credor', header: 'CREDOR' },
        { key: 'cliente_nome', header: 'NOME DO CLIENTE' },
        { key: 'cpf', header: 'CPF' },
        { key: 'data', header: 'DATA' },
        { key: 'data_envio', header: 'HORÁRIO' },
      ]
      worksheetEmails.columns = [
        { key: 'credor', header: 'CREDOR' },
        { key: 'cliente_nome', header: 'NOME DO CLIENTE' },
        { key: 'cpf', header: 'CPF' },
        { key: 'data', header: 'DATA' },
        { key: 'data_envio', header: 'HORÁRIO' },
      ]
      worksheetWhatsapp.columns = [
        { key: 'credor', header: 'CREDOR' },
        { key: 'cliente_nome', header: 'NOME DO CLIENTE' },
        { key: 'cpf', header: 'CPF' },
        { key: 'data', header: 'DATA' },
        { key: 'data_envio', header: 'HORÁRIO' },
      ]

      workSheetTotais.addRow([
        this.totaisLigacoes,
        this.totaisSMS,
        this.totaisEmails,
        this.totaisWhatsApp,
      ])

      this.itemsLigacoes.map(item => {
        worksheetLigacoes.addRow([
          item.credor,
          item.cliente_nome,
          item.cpf,
          item.data_envio ? this.getDateOrTimezone(item.data_envio, 'data') : '',
          item.data_envio ? this.getDateOrTimezone(item.data_envio) : '',
          item.resultado,
          item.origem,
        ])
      })
      this.itemsSMS.map(item => {
        worksheetSms.addRow([
          item.credor,
          item.cliente_nome,
          item.cpf,
          item.data_envio ? this.getDateOrTimezone(item.data_envio, 'data') : '',
          item.data_envio ? this.getDateOrTimezone(item.data_envio) : '',
        ])
      })
      this.itemsEmails.map(item => {
        worksheetEmails.addRow([
          item.credor,
          item.cliente_nome,
          item.cpf,
          item.data_envio ? this.getDateOrTimezone(item.data_envio, 'data') : '',
          item.data_envio ? this.getDateOrTimezone(item.data_envio) : '',
        ])
      })
      this.itemsWhatsApp.map(item => {
        worksheetWhatsapp.addRow([
          item.credor,
          item.cliente_nome,
          item.cpf,
          item.data_envio ? this.getDateOrTimezone(item.data_envio, 'data') : '',
          item.data_envio ? this.getDateOrTimezone(item.data_envio) : '',
        ])
      })
      worksheetLigacoes.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      workSheetTotais.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      workSheetTotais.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheetLigacoes.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheetSms.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheetSms.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheetEmails.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheetEmails.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheetWhatsapp.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheetWhatsapp.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Ações de Cobranças', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    getDateOrTimezone(date, type) {
      const splitDate = date.split(' ')
      if (type === 'data') {
        const objDate = new Date(date)
        const day = objDate.getDate()
        const month = objDate.getMonth() + 1
        const year = objDate.getFullYear()
        return `${day}/${month < 10 ? '0' : ''}${month}/${year}`
      }
      return splitDate[1]
    },
  },
}
</script>
